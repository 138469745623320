<template>
    <div>
        <div class="flex justify-content-center">
            <Card style="width: 25em" class="mt-5">
                <template #header>
                    <div class="flex justify-content-center mt-4">
                        <img alt="user header" src="layout/images/logo_login.png" style="width: 10em" />
                    </div>
                </template>
                <template #content>
                    <div class="text-center">
                        <h4>PORTAL DE SERVIÇOS</h4>
                    </div>
                    <erros-box :erros="erros"></erros-box>
                    <div class="formgrid grid p-fluid">
                        <div class="field col-12 mt-2">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-user"></i>
                                </span>
                                <InputText v-model="usuario" @input="v$.usuario.$touch()" placeholder="Usuário" />
                            </div>
                            <small class="p-error" v-if="v$.usuario.$error">Usuário é obrigatório</small>
                        </div>
                        <div class="field col-12 mt-2">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-key"></i>
                                </span>
                                <InputText type="password" v-model="senha" @input="v$.senha.$touch()" @keypress.enter="recaptcha" placeholder="Senha" />
                            </div>
                            <small class="p-error" v-if="v$.senha.$error">Senha é obrigatória</small>
                        </div>
                    </div>
                    <div class="col-12 inline-flex flex-column justify-content-center">
                        <Button label="Entrar" icon="pi pi-check" @click="recaptcha" class="btnLogin" />
                        <div class="mt-4 text-center">
                            <strong>v{{ versao }}</strong>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AuthServices from './AuthServices';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    data() {
        return {
            usuario: '',
            senha: '',
            erros: [],
            versao: process.env.VUE_APP_VERSION,
        };
    },

    validations() {
        return {
            usuario: { required },
            senha: { required },
        };
    },

    beforeCreate() {
        this.$store.dispatch('verificarVersao');
    },

    methods: {
        async recaptcha() {
            this.v$.$touch();
            if (this.v$.$invalid) return;
            this.$store.dispatch('addRequest');
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.login(token);
        },

        login(token) {
            let loginDto = {
                usuario: this.usuario.toLowerCase(),
                senha: btoa(this.senha),
                token: token,
            };

            AuthServices.login(loginDto).then((response) => {
                if (response?.success) {
                    this.$store.dispatch('login', response.data);
                    this.reset();
                    this.processarRota();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        processarRota() {
            if (this.$store.getters.usuario.aspl === 'True') {
                this.$router.push('/atualizarsenha');
            } else {
                if (this.$route.query.redirect) {
                    let redirect = this.$route.query.redirect;
                    delete this.$route.query.redirect;
                    this.$router.push({
                        path: redirect,
                        query: this.$route.query,
                    });
                } else {
                    this.$router.push('/');
                }
            }
        },

        reset() {
            this.usuario = '';
            this.senha = '';
            this.erros = [];
            this.v$.$reset();
        },

        recuperarSenha() {
            this.$router.push('/recuperarsenha');
        },
    },

    mounted() {
        this.$store.dispatch('carregarConfigApp');
    },
};
</script>
